"use client";

import { useState } from "react";

import {
  Group,
  // Image,
  Menu,
  // UnstyledButton,
  Button,
  // rem,
  // useMantineColorScheme,
  // ActionIcon,
  type MenuProps,
  Text,
} from "@mantine/core";
import { availableLanguageTags } from "@/paraglide/runtime";
import Link from "next/link";
import { IconChevronDown } from "@tabler/icons-react";
import { languageTag } from "@/paraglide/runtime";
import { useRouter } from "next/router";
type LanguagePickerProps = {
  type?: "collapsed" | "expanded";
  size?: number;
} & MenuProps;

export const LanguagePicker = ({ type = "collapsed" }: LanguagePickerProps) => {
  const { asPath } = useRouter();
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="sm"
    >
      <Menu.Target>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 8,
            transition: "background-color 150ms ease",
          }}
        >
          {type === "expanded" && (
            <IconChevronDown
              size="1rem"
              stroke={1.5}
              style={{
                marginRight: 4,
                transition: "transform 150ms ease",
                transform: opened ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          )}
          <Group gap="xs">
            <Button
              variant="outline"
              size="compact-sm"
              styles={{ section: { margin: 0 } }}
              rightSection={
                <IconChevronDown
                  size="1rem"
                  stroke={1.5}
                  style={{
                    transition: "transform 100ms ease",
                    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              {languageTag().toLocaleUpperCase()}
            </Button>
            {/* {type === "expanded" && <span>{selected?.label}</span>} */}
          </Group>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {availableLanguageTags
          .filter((lang) => lang !== languageTag())
          .map((lang) => (
            <Menu.Item
              key={lang}
              component={Link}
              href={{ pathname: asPath }}
              locale={lang}
              hrefLang={lang}
            >
              <Group>
                <Text> {lang.toLocaleUpperCase()}</Text>
              </Group>
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  );
};
