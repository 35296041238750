/* eslint-disable */
import { languageTag } from "./runtime.js"
import * as es from "./messages/es.js"
import * as en from "./messages/en.js"
import * as pt from "./messages/pt.js"


/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hello_world = (params = {}, options = {}) => {
	return {
		en: en.hello_world,
		es: es.hello_world,
		pt: pt.hello_world
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_equal_emu_dig = (params = {}, options = {}) => {
	return {
		en: en.large_equal_emu_dig,
		es: es.large_equal_emu_dig,
		pt: pt.large_equal_emu_dig
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_tired_baboon_lift = (params = {}, options = {}) => {
	return {
		en: en.bold_tired_baboon_lift,
		es: es.bold_tired_baboon_lift,
		pt: pt.bold_tired_baboon_lift
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const upper_lost_ray_buy = (params = {}, options = {}) => {
	return {
		en: en.upper_lost_ray_buy,
		es: es.upper_lost_ray_buy,
		pt: pt.upper_lost_ray_buy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const acidic_grassy_wombat_quiz = (params = {}, options = {}) => {
	return {
		en: en.acidic_grassy_wombat_quiz,
		es: es.acidic_grassy_wombat_quiz,
		pt: pt.acidic_grassy_wombat_quiz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const extra_stale_panther_pinch = (params = {}, options = {}) => {
	return {
		en: en.extra_stale_panther_pinch,
		es: es.extra_stale_panther_pinch,
		pt: pt.extra_stale_panther_pinch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_fair_mare_play = (params = {}, options = {}) => {
	return {
		en: en.helpful_fair_mare_play,
		es: es.helpful_fair_mare_play,
		pt: pt.helpful_fair_mare_play
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_grassy_pug_dream = (params = {}, options = {}) => {
	return {
		en: en.such_grassy_pug_dream,
		es: es.such_grassy_pug_dream,
		pt: pt.such_grassy_pug_dream
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_deft_toad_hurl = (params = {}, options = {}) => {
	return {
		en: en.house_deft_toad_hurl,
		es: es.house_deft_toad_hurl,
		pt: pt.house_deft_toad_hurl
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_dry_okapi_pick = (params = {}, options = {}) => {
	return {
		en: en.bland_dry_okapi_pick,
		es: es.bland_dry_okapi_pick,
		pt: pt.bland_dry_okapi_pick
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_fit_mouse_soar = (params = {}, options = {}) => {
	return {
		en: en.muddy_fit_mouse_soar,
		es: es.muddy_fit_mouse_soar,
		pt: pt.muddy_fit_mouse_soar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mealy_patient_nils_dine = (params = {}, options = {}) => {
	return {
		en: en.mealy_patient_nils_dine,
		es: es.mealy_patient_nils_dine,
		pt: pt.mealy_patient_nils_dine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_aqua_sparrow_drop = (params = {}, options = {}) => {
	return {
		en: en.curly_aqua_sparrow_drop,
		es: es.curly_aqua_sparrow_drop,
		pt: pt.curly_aqua_sparrow_drop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sweet_witty_skunk_gaze = (params = {}, options = {}) => {
	return {
		en: en.sweet_witty_skunk_gaze,
		es: es.sweet_witty_skunk_gaze,
		pt: pt.sweet_witty_skunk_gaze
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ok_direct_koala_enjoy = (params = {}, options = {}) => {
	return {
		en: en.ok_direct_koala_enjoy,
		es: es.ok_direct_koala_enjoy,
		pt: pt.ok_direct_koala_enjoy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_known_herring_fear = (params = {}, options = {}) => {
	return {
		en: en.house_known_herring_fear,
		es: es.house_known_herring_fear,
		pt: pt.house_known_herring_fear
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_cuddly_pig_honor = (params = {}, options = {}) => {
	return {
		en: en.busy_cuddly_pig_honor,
		es: es.busy_cuddly_pig_honor,
		pt: pt.busy_cuddly_pig_honor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_stock_buzzard_grow = (params = {}, options = {}) => {
	return {
		en: en.kind_stock_buzzard_grow,
		es: es.kind_stock_buzzard_grow,
		pt: pt.kind_stock_buzzard_grow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_close_chicken_fall = (params = {}, options = {}) => {
	return {
		en: en.white_close_chicken_fall,
		es: es.white_close_chicken_fall,
		pt: pt.white_close_chicken_fall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const left_alive_florian_aspire = (params = {}, options = {}) => {
	return {
		en: en.left_alive_florian_aspire,
		es: es.left_alive_florian_aspire,
		pt: pt.left_alive_florian_aspire
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const keen_chunky_mammoth_pout = (params = {}, options = {}) => {
	return {
		en: en.keen_chunky_mammoth_pout,
		es: es.keen_chunky_mammoth_pout,
		pt: pt.keen_chunky_mammoth_pout
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_fancy_mammoth_sing = (params = {}, options = {}) => {
	return {
		en: en.smart_fancy_mammoth_sing,
		es: es.smart_fancy_mammoth_sing,
		pt: pt.smart_fancy_mammoth_sing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_any_stork_zip = (params = {}, options = {}) => {
	return {
		en: en.few_any_stork_zip,
		es: es.few_any_stork_zip,
		pt: pt.few_any_stork_zip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_polite_niklas_devour = (params = {}, options = {}) => {
	return {
		en: en.close_polite_niklas_devour,
		es: es.close_polite_niklas_devour,
		pt: pt.close_polite_niklas_devour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_bold_bulldog_work = (params = {}, options = {}) => {
	return {
		en: en.sea_bold_bulldog_work,
		es: es.sea_bold_bulldog_work,
		pt: pt.sea_bold_bulldog_work
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_patchy_crossbill_wish = (params = {}, options = {}) => {
	return {
		en: en.that_patchy_crossbill_wish,
		es: es.that_patchy_crossbill_wish,
		pt: pt.that_patchy_crossbill_wish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_vexed_jellyfish_stab = (params = {}, options = {}) => {
	return {
		en: en.every_vexed_jellyfish_stab,
		es: es.every_vexed_jellyfish_stab,
		pt: pt.every_vexed_jellyfish_stab
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_fit_platypus_ripple = (params = {}, options = {}) => {
	return {
		en: en.short_fit_platypus_ripple,
		es: es.short_fit_platypus_ripple,
		pt: pt.short_fit_platypus_ripple
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_strong_kudu_boost = (params = {}, options = {}) => {
	return {
		en: en.yummy_strong_kudu_boost,
		es: es.yummy_strong_kudu_boost,
		pt: pt.yummy_strong_kudu_boost
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_orange_alligator_flop = (params = {}, options = {}) => {
	return {
		en: en.fresh_orange_alligator_flop,
		es: es.fresh_orange_alligator_flop,
		pt: pt.fresh_orange_alligator_flop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const livid_even_pug_amuse = (params = {}, options = {}) => {
	return {
		en: en.livid_even_pug_amuse,
		es: es.livid_even_pug_amuse,
		pt: pt.livid_even_pug_amuse
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_royal_shad_honor = (params = {}, options = {}) => {
	return {
		en: en.flat_royal_shad_honor,
		es: es.flat_royal_shad_honor,
		pt: pt.flat_royal_shad_honor
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_sunny_bat_list = (params = {}, options = {}) => {
	return {
		en: en.every_sunny_bat_list,
		es: es.every_sunny_bat_list,
		pt: pt.every_sunny_bat_list
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gray_north_quail_list = (params = {}, options = {}) => {
	return {
		en: en.gray_north_quail_list,
		es: es.gray_north_quail_list,
		pt: pt.gray_north_quail_list
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const acidic_ornate_pony_reap = (params = {}, options = {}) => {
	return {
		en: en.acidic_ornate_pony_reap,
		es: es.acidic_ornate_pony_reap,
		pt: pt.acidic_ornate_pony_reap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_small_dog_launch = (params = {}, options = {}) => {
	return {
		en: en.smart_small_dog_launch,
		es: es.smart_small_dog_launch,
		pt: pt.smart_small_dog_launch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_gray_stork_cook = (params = {}, options = {}) => {
	return {
		en: en.least_gray_stork_cook,
		es: es.least_gray_stork_cook,
		pt: pt.least_gray_stork_cook
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const factual_icy_falcon_wish = (params = {}, options = {}) => {
	return {
		en: en.factual_icy_falcon_wish,
		es: es.factual_icy_falcon_wish,
		pt: pt.factual_icy_falcon_wish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const antsy_loved_newt_accept = (params = {}, options = {}) => {
	return {
		en: en.antsy_loved_newt_accept,
		es: es.antsy_loved_newt_accept,
		pt: pt.antsy_loved_newt_accept
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const icy_dull_macaw_build = (params = {}, options = {}) => {
	return {
		en: en.icy_dull_macaw_build,
		es: es.icy_dull_macaw_build,
		pt: pt.icy_dull_macaw_build
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heavy_patient_insect_climb = (params = {}, options = {}) => {
	return {
		en: en.heavy_patient_insect_climb,
		es: es.heavy_patient_insect_climb,
		pt: pt.heavy_patient_insect_climb
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lucky_mealy_clownfish_dart = (params = {}, options = {}) => {
	return {
		en: en.lucky_mealy_clownfish_dart,
		es: es.lucky_mealy_clownfish_dart,
		pt: pt.lucky_mealy_clownfish_dart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_lofty_lizard_delight = (params = {}, options = {}) => {
	return {
		en: en.direct_lofty_lizard_delight,
		es: es.direct_lofty_lizard_delight,
		pt: pt.direct_lofty_lizard_delight
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_seemly_deer_fond = (params = {}, options = {}) => {
	return {
		en: en.curly_seemly_deer_fond,
		es: es.curly_seemly_deer_fond,
		pt: pt.curly_seemly_deer_fond
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const careful_spry_ibex_embrace = (params = {}, options = {}) => {
	return {
		en: en.careful_spry_ibex_embrace,
		es: es.careful_spry_ibex_embrace,
		pt: pt.careful_spry_ibex_embrace
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_livid_tern_enrich = (params = {}, options = {}) => {
	return {
		en: en.short_livid_tern_enrich,
		es: es.short_livid_tern_enrich,
		pt: pt.short_livid_tern_enrich
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const stale_fair_wren_drip = (params = {}, options = {}) => {
	return {
		en: en.stale_fair_wren_drip,
		es: es.stale_fair_wren_drip,
		pt: pt.stale_fair_wren_drip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const neat_mild_fly_jolt = (params = {}, options = {}) => {
	return {
		en: en.neat_mild_fly_jolt,
		es: es.neat_mild_fly_jolt,
		pt: pt.neat_mild_fly_jolt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wise_late_salmon_buy = (params = {}, options = {}) => {
	return {
		en: en.wise_late_salmon_buy,
		es: es.wise_late_salmon_buy,
		pt: pt.wise_late_salmon_buy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_cute_baboon_dart = (params = {}, options = {}) => {
	return {
		en: en.last_cute_baboon_dart,
		es: es.last_cute_baboon_dart,
		pt: pt.last_cute_baboon_dart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const still_alert_jellyfish_find = (params = {}, options = {}) => {
	return {
		en: en.still_alert_jellyfish_find,
		es: es.still_alert_jellyfish_find,
		pt: pt.still_alert_jellyfish_find
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const odd_careful_beetle_fulfill = (params = {}, options = {}) => {
	return {
		en: en.odd_careful_beetle_fulfill,
		es: es.odd_careful_beetle_fulfill,
		pt: pt.odd_careful_beetle_fulfill
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loose_fluffy_niklas_persist = (params = {}, options = {}) => {
	return {
		en: en.loose_fluffy_niklas_persist,
		es: es.loose_fluffy_niklas_persist,
		pt: pt.loose_fluffy_niklas_persist
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_bold_fireant_intend = (params = {}, options = {}) => {
	return {
		en: en.ago_bold_fireant_intend,
		es: es.ago_bold_fireant_intend,
		pt: pt.ago_bold_fireant_intend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_quiet_jaguar_surge = (params = {}, options = {}) => {
	return {
		en: en.free_quiet_jaguar_surge,
		es: es.free_quiet_jaguar_surge,
		pt: pt.free_quiet_jaguar_surge
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_misty_okapi_lift = (params = {}, options = {}) => {
	return {
		en: en.bald_misty_okapi_lift,
		es: es.bald_misty_okapi_lift,
		pt: pt.bald_misty_okapi_lift
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fair_knotty_panther_reside = (params = {}, options = {}) => {
	return {
		en: en.fair_knotty_panther_reside,
		es: es.fair_knotty_panther_reside,
		pt: pt.fair_knotty_panther_reside
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_grand_mayfly_trim = (params = {}, options = {}) => {
	return {
		en: en.curly_grand_mayfly_trim,
		es: es.curly_grand_mayfly_trim,
		pt: pt.curly_grand_mayfly_trim
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmpC = (params = {}, options = {}) => {
	return {
		en: en.lmpC,
		es: es.lmpC,
		pt: pt.lmpC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calcFromHC = (params = {}, options = {}) => {
	return {
		en: en.calcFromHC,
		es: es.calcFromHC,
		pt: pt.calcFromHC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_flaky_walrus_advise = (params = {}, options = {}) => {
	return {
		en: en.large_flaky_walrus_advise,
		es: es.large_flaky_walrus_advise,
		pt: pt.large_flaky_walrus_advise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_lower_wombat_spark = (params = {}, options = {}) => {
	return {
		en: en.tasty_lower_wombat_spark,
		es: es.tasty_lower_wombat_spark,
		pt: pt.tasty_lower_wombat_spark
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_sad_stingray_work = (params = {}, options = {}) => {
	return {
		en: en.trite_sad_stingray_work,
		es: es.trite_sad_stingray_work,
		pt: pt.trite_sad_stingray_work
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HC = (params = {}, options = {}) => {
	return {
		en: en.HC,
		es: es.HC,
		pt: pt.HC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AC = (params = {}, options = {}) => {
	return {
		en: en.AC,
		es: es.AC,
		pt: pt.AC
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const FL = (params = {}, options = {}) => {
	return {
		en: en.FL,
		es: es.FL,
		pt: pt.FL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EFW = (params = {}, options = {}) => {
	return {
		en: en.EFW,
		es: es.EFW,
		pt: pt.EFW
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const CRL = (params = {}, options = {}) => {
	return {
		en: en.CRL,
		es: es.CRL,
		pt: pt.CRL
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hc = (params = {}, options = {}) => {
	return {
		en: en.hc,
		es: es.hc,
		pt: pt.hc
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ac = (params = {}, options = {}) => {
	return {
		en: en.ac,
		es: es.ac,
		pt: pt.ac
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fl = (params = {}, options = {}) => {
	return {
		en: en.fl,
		es: es.fl,
		pt: pt.fl
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const efw = (params = {}, options = {}) => {
	return {
		en: en.efw,
		es: es.efw,
		pt: pt.efw
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crl = (params = {}, options = {}) => {
	return {
		en: en.crl,
		es: es.crl,
		pt: pt.crl
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grand_busy_insect_swim = (params = {}, options = {}) => {
	return {
		en: en.grand_busy_insect_swim,
		es: es.grand_busy_insect_swim,
		pt: pt.grand_busy_insect_swim
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_round_deer_jolt = (params = {}, options = {}) => {
	return {
		en: en.loved_round_deer_jolt,
		es: es.loved_round_deer_jolt,
		pt: pt.loved_round_deer_jolt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const leftUterineArteryPulsatilityIndex = (params = {}, options = {}) => {
	return {
		en: en.leftUterineArteryPulsatilityIndex,
		es: es.leftUterineArteryPulsatilityIndex,
		pt: pt.leftUterineArteryPulsatilityIndex
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const rightUterineArteryPulsatilityIndex = (params = {}, options = {}) => {
	return {
		en: en.rightUterineArteryPulsatilityIndex,
		es: es.rightUterineArteryPulsatilityIndex,
		pt: pt.rightUterineArteryPulsatilityIndex
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const umbilicalArteryPulsatilityIndex = (params = {}, options = {}) => {
	return {
		en: en.umbilicalArteryPulsatilityIndex,
		es: es.umbilicalArteryPulsatilityIndex,
		pt: pt.umbilicalArteryPulsatilityIndex
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const middleCerebralArteryPI = (params = {}, options = {}) => {
	return {
		en: en.middleCerebralArteryPI,
		es: es.middleCerebralArteryPI,
		pt: pt.middleCerebralArteryPI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const doctusVenosusPI = (params = {}, options = {}) => {
	return {
		en: en.doctusVenosusPI,
		es: es.doctusVenosusPI,
		pt: pt.doctusVenosusPI
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ weeksNumber: NonNullable<unknown> }} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next_any_orangutan_compose = (params , options = {}) => {
	return {
		en: en.next_any_orangutan_compose,
		es: es.next_any_orangutan_compose,
		pt: pt.next_any_orangutan_compose
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_still_mare_reap = (params = {}, options = {}) => {
	return {
		en: en.fluffy_still_mare_reap,
		es: es.fluffy_still_mare_reap,
		pt: pt.fluffy_still_mare_reap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_alive_camel_drop = (params = {}, options = {}) => {
	return {
		en: en.nimble_alive_camel_drop,
		es: es.nimble_alive_camel_drop,
		pt: pt.nimble_alive_camel_drop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const EDD = (params = {}, options = {}) => {
	return {
		en: en.EDD,
		es: es.EDD,
		pt: pt.EDD
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const HCp = (params = {}, options = {}) => {
	return {
		en: en.HCp,
		es: es.HCp,
		pt: pt.HCp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ACp = (params = {}, options = {}) => {
	return {
		en: en.ACp,
		es: es.ACp,
		pt: pt.ACp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const FLp = (params = {}, options = {}) => {
	return {
		en: en.FLp,
		es: es.FLp,
		pt: pt.FLp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arable_weird_camel_pat = (params = {}, options = {}) => {
	return {
		en: en.arable_weird_camel_pat,
		es: es.arable_weird_camel_pat,
		pt: pt.arable_weird_camel_pat
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_fit_slug_promise = (params = {}, options = {}) => {
	return {
		en: en.yummy_fit_slug_promise,
		es: es.yummy_fit_slug_promise,
		pt: pt.yummy_fit_slug_promise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mealy_strong_crow_link = (params = {}, options = {}) => {
	return {
		en: en.mealy_strong_crow_link,
		es: es.mealy_strong_crow_link,
		pt: pt.mealy_strong_crow_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const next_quiet_tortoise_bubble = (params = {}, options = {}) => {
	return {
		en: en.next_quiet_tortoise_bubble,
		es: es.next_quiet_tortoise_bubble,
		pt: pt.next_quiet_tortoise_bubble
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const watery_less_shark_fall = (params = {}, options = {}) => {
	return {
		en: en.watery_less_shark_fall,
		es: es.watery_less_shark_fall,
		pt: pt.watery_less_shark_fall
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_just_wolf_assure = (params = {}, options = {}) => {
	return {
		en: en.helpful_just_wolf_assure,
		es: es.helpful_just_wolf_assure,
		pt: pt.helpful_just_wolf_assure
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_new_ray_foster = (params = {}, options = {}) => {
	return {
		en: en.wild_new_ray_foster,
		es: es.wild_new_ray_foster,
		pt: pt.wild_new_ray_foster
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_blue_seal_edit = (params = {}, options = {}) => {
	return {
		en: en.direct_blue_seal_edit,
		es: es.direct_blue_seal_edit,
		pt: pt.direct_blue_seal_edit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const misty_wise_fly_ask = (params = {}, options = {}) => {
	return {
		en: en.misty_wise_fly_ask,
		es: es.misty_wise_fly_ask,
		pt: pt.misty_wise_fly_ask
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_lime_robin_mend = (params = {}, options = {}) => {
	return {
		en: en.red_lime_robin_mend,
		es: es.red_lime_robin_mend,
		pt: pt.red_lime_robin_mend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_lazy_turtle_mend = (params = {}, options = {}) => {
	return {
		en: en.each_lazy_turtle_mend,
		es: es.each_lazy_turtle_mend,
		pt: pt.each_lazy_turtle_mend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const true_quiet_grizzly_enjoy = (params = {}, options = {}) => {
	return {
		en: en.true_quiet_grizzly_enjoy,
		es: es.true_quiet_grizzly_enjoy,
		pt: pt.true_quiet_grizzly_enjoy
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loose_steep_herring_renew = (params = {}, options = {}) => {
	return {
		en: en.loose_steep_herring_renew,
		es: es.loose_steep_herring_renew,
		pt: pt.loose_steep_herring_renew
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_bald_turtle_hike = (params = {}, options = {}) => {
	return {
		en: en.bland_bald_turtle_hike,
		es: es.bland_bald_turtle_hike,
		pt: pt.bland_bald_turtle_hike
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_soft_ape_nail = (params = {}, options = {}) => {
	return {
		en: en.kind_soft_ape_nail,
		es: es.kind_soft_ape_nail,
		pt: pt.kind_soft_ape_nail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_curly_cockroach_quell = (params = {}, options = {}) => {
	return {
		en: en.witty_curly_cockroach_quell,
		es: es.witty_curly_cockroach_quell,
		pt: pt.witty_curly_cockroach_quell
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trick_stout_crow_snip = (params = {}, options = {}) => {
	return {
		en: en.trick_stout_crow_snip,
		es: es.trick_stout_crow_snip,
		pt: pt.trick_stout_crow_snip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_real_vulture_agree = (params = {}, options = {}) => {
	return {
		en: en.wild_real_vulture_agree,
		es: es.wild_real_vulture_agree,
		pt: pt.wild_real_vulture_agree
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_ornate_cow_build = (params = {}, options = {}) => {
	return {
		en: en.that_ornate_cow_build,
		es: es.that_ornate_cow_build,
		pt: pt.that_ornate_cow_build
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_direct_snake_roam = (params = {}, options = {}) => {
	return {
		en: en.crisp_direct_snake_roam,
		es: es.crisp_direct_snake_roam,
		pt: pt.crisp_direct_snake_roam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_elegant_llama_soar = (params = {}, options = {}) => {
	return {
		en: en.sharp_elegant_llama_soar,
		es: es.sharp_elegant_llama_soar,
		pt: pt.sharp_elegant_llama_soar
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cancel = (params = {}, options = {}) => {
	return {
		en: en.cancel,
		es: es.cancel,
		pt: pt.cancel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const confirm = (params = {}, options = {}) => {
	return {
		en: en.confirm,
		es: es.confirm,
		pt: pt.confirm
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const strong_lucky_canary_stop = (params = {}, options = {}) => {
	return {
		en: en.strong_lucky_canary_stop,
		es: es.strong_lucky_canary_stop,
		pt: pt.strong_lucky_canary_stop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_salty_canary_attend = (params = {}, options = {}) => {
	return {
		en: en.tangy_salty_canary_attend,
		es: es.tangy_salty_canary_attend,
		pt: pt.tangy_salty_canary_attend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const many_real_antelope_dream = (params = {}, options = {}) => {
	return {
		en: en.many_real_antelope_dream,
		es: es.many_real_antelope_dream,
		pt: pt.many_real_antelope_dream
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wide_slow_pelican_read = (params = {}, options = {}) => {
	return {
		en: en.wide_slow_pelican_read,
		es: es.wide_slow_pelican_read,
		pt: pt.wide_slow_pelican_read
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tense_east_llama_pause = (params = {}, options = {}) => {
	return {
		en: en.tense_east_llama_pause,
		es: es.tense_east_llama_pause,
		pt: pt.tense_east_llama_pause
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jumpy_maroon_cowfish_adapt = (params = {}, options = {}) => {
	return {
		en: en.jumpy_maroon_cowfish_adapt,
		es: es.jumpy_maroon_cowfish_adapt,
		pt: pt.jumpy_maroon_cowfish_adapt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crazy_quaint_gorilla_forgive = (params = {}, options = {}) => {
	return {
		en: en.crazy_quaint_gorilla_forgive,
		es: es.crazy_quaint_gorilla_forgive,
		pt: pt.crazy_quaint_gorilla_forgive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const weird_bald_guppy_flip = (params = {}, options = {}) => {
	return {
		en: en.weird_bald_guppy_flip,
		es: es.weird_bald_guppy_flip,
		pt: pt.weird_bald_guppy_flip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crisp_spare_mink_dazzle = (params = {}, options = {}) => {
	return {
		en: en.crisp_spare_mink_dazzle,
		es: es.crisp_spare_mink_dazzle,
		pt: pt.crisp_spare_mink_dazzle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_frail_bee_gleam = (params = {}, options = {}) => {
	return {
		en: en.lofty_frail_bee_gleam,
		es: es.lofty_frail_bee_gleam,
		pt: pt.lofty_frail_bee_gleam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const careful_level_marmot_imagine = (params = {}, options = {}) => {
	return {
		en: en.careful_level_marmot_imagine,
		es: es.careful_level_marmot_imagine,
		pt: pt.careful_level_marmot_imagine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lower_crazy_boar_wish = (params = {}, options = {}) => {
	return {
		en: en.lower_crazy_boar_wish,
		es: es.lower_crazy_boar_wish,
		pt: pt.lower_crazy_boar_wish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const round_jolly_cod_dream = (params = {}, options = {}) => {
	return {
		en: en.round_jolly_cod_dream,
		es: es.round_jolly_cod_dream,
		pt: pt.round_jolly_cod_dream
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_round_jay_link = (params = {}, options = {}) => {
	return {
		en: en.sleek_round_jay_link,
		es: es.sleek_round_jay_link,
		pt: pt.sleek_round_jay_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_vexed_cheetah_edit = (params = {}, options = {}) => {
	return {
		en: en.nimble_vexed_cheetah_edit,
		es: es.nimble_vexed_cheetah_edit,
		pt: pt.nimble_vexed_cheetah_edit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const orange_bland_chipmunk_support = (params = {}, options = {}) => {
	return {
		en: en.orange_bland_chipmunk_support,
		es: es.orange_bland_chipmunk_support,
		pt: pt.orange_bland_chipmunk_support
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const silly_good_dove_gleam = (params = {}, options = {}) => {
	return {
		en: en.silly_good_dove_gleam,
		es: es.silly_good_dove_gleam,
		pt: pt.silly_good_dove_gleam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_wild_wolf_pull = (params = {}, options = {}) => {
	return {
		en: en.suave_wild_wolf_pull,
		es: es.suave_wild_wolf_pull,
		pt: pt.suave_wild_wolf_pull
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dull_low_bullock_thrive = (params = {}, options = {}) => {
	return {
		en: en.dull_low_bullock_thrive,
		es: es.dull_low_bullock_thrive,
		pt: pt.dull_low_bullock_thrive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const noisy_lazy_quail_buzz = (params = {}, options = {}) => {
	return {
		en: en.noisy_lazy_quail_buzz,
		es: es.noisy_lazy_quail_buzz,
		pt: pt.noisy_lazy_quail_buzz
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_best_lionfish_read = (params = {}, options = {}) => {
	return {
		en: en.red_best_lionfish_read,
		es: es.red_best_lionfish_read,
		pt: pt.red_best_lionfish_read
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_least_lobster_radiate = (params = {}, options = {}) => {
	return {
		en: en.fluffy_least_lobster_radiate,
		es: es.fluffy_least_lobster_radiate,
		pt: pt.fluffy_least_lobster_radiate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const full_round_pelican_mix = (params = {}, options = {}) => {
	return {
		en: en.full_round_pelican_mix,
		es: es.full_round_pelican_mix,
		pt: pt.full_round_pelican_mix
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_steep_flamingo_adapt = (params = {}, options = {}) => {
	return {
		en: en.wild_steep_flamingo_adapt,
		es: es.wild_steep_flamingo_adapt,
		pt: pt.wild_steep_flamingo_adapt
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_merry_piranha_dart = (params = {}, options = {}) => {
	return {
		en: en.last_merry_piranha_dart,
		es: es.last_merry_piranha_dart,
		pt: pt.last_merry_piranha_dart
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_inclusive_bat_smile = (params = {}, options = {}) => {
	return {
		en: en.trite_inclusive_bat_smile,
		es: es.trite_inclusive_bat_smile,
		pt: pt.trite_inclusive_bat_smile
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const these_lucky_meerkat_nourish = (params = {}, options = {}) => {
	return {
		en: en.these_lucky_meerkat_nourish,
		es: es.these_lucky_meerkat_nourish,
		pt: pt.these_lucky_meerkat_nourish
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_these_husky_aspire = (params = {}, options = {}) => {
	return {
		en: en.active_these_husky_aspire,
		es: es.active_these_husky_aspire,
		pt: pt.active_these_husky_aspire
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const less_novel_jackal_beam = (params = {}, options = {}) => {
	return {
		en: en.less_novel_jackal_beam,
		es: es.less_novel_jackal_beam,
		pt: pt.less_novel_jackal_beam
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_silly_cat_launch = (params = {}, options = {}) => {
	return {
		en: en.loud_silly_cat_launch,
		es: es.loud_silly_cat_launch,
		pt: pt.loud_silly_cat_launch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bright_pretty_blackbird_renew = (params = {}, options = {}) => {
	return {
		en: en.bright_pretty_blackbird_renew,
		es: es.bright_pretty_blackbird_renew,
		pt: pt.bright_pretty_blackbird_renew
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_sleek_canary_boost = (params = {}, options = {}) => {
	return {
		en: en.loud_sleek_canary_boost,
		es: es.loud_sleek_canary_boost,
		pt: pt.loud_sleek_canary_boost
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_wild_canary_earn = (params = {}, options = {}) => {
	return {
		en: en.empty_wild_canary_earn,
		es: es.empty_wild_canary_earn,
		pt: pt.empty_wild_canary_earn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fair_large_seahorse_transform = (params = {}, options = {}) => {
	return {
		en: en.fair_large_seahorse_transform,
		es: es.fair_large_seahorse_transform,
		pt: pt.fair_large_seahorse_transform
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_heavy_opossum_radiate = (params = {}, options = {}) => {
	return {
		en: en.zippy_heavy_opossum_radiate,
		es: es.zippy_heavy_opossum_radiate,
		pt: pt.zippy_heavy_opossum_radiate
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const smart_aware_impala_tickle = (params = {}, options = {}) => {
	return {
		en: en.smart_aware_impala_tickle,
		es: es.smart_aware_impala_tickle,
		pt: pt.smart_aware_impala_tickle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_civil_pony_work = (params = {}, options = {}) => {
	return {
		en: en.slimy_civil_pony_work,
		es: es.slimy_civil_pony_work,
		pt: pt.slimy_civil_pony_work
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_key_fish_burn = (params = {}, options = {}) => {
	return {
		en: en.major_key_fish_burn,
		es: es.major_key_fish_burn,
		pt: pt.major_key_fish_burn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flaky_civil_nuthatch_tickle = (params = {}, options = {}) => {
	return {
		en: en.flaky_civil_nuthatch_tickle,
		es: es.flaky_civil_nuthatch_tickle,
		pt: pt.flaky_civil_nuthatch_tickle
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_brief_toucan_engage = (params = {}, options = {}) => {
	return {
		en: en.every_brief_toucan_engage,
		es: es.every_brief_toucan_engage,
		pt: pt.every_brief_toucan_engage
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_dirty_marmot_hack = (params = {}, options = {}) => {
	return {
		en: en.sound_dirty_marmot_hack,
		es: es.sound_dirty_marmot_hack,
		pt: pt.sound_dirty_marmot_hack
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_candid_skate_sing = (params = {}, options = {}) => {
	return {
		en: en.few_candid_skate_sing,
		es: es.few_candid_skate_sing,
		pt: pt.few_candid_skate_sing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ provider: NonNullable<unknown> }} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mild_sound_carp_peek = (params , options = {}) => {
	return {
		en: en.mild_sound_carp_peek,
		es: es.mild_sound_carp_peek,
		pt: pt.mild_sound_carp_peek
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_best_marten_feel = (params = {}, options = {}) => {
	return {
		en: en.quick_best_marten_feel,
		es: es.quick_best_marten_feel,
		pt: pt.quick_best_marten_feel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_sad_gadfly_pick = (params = {}, options = {}) => {
	return {
		en: en.topical_sad_gadfly_pick,
		es: es.topical_sad_gadfly_pick,
		pt: pt.topical_sad_gadfly_pick
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const plane_day_turkey_nurture = (params = {}, options = {}) => {
	return {
		en: en.plane_day_turkey_nurture,
		es: es.plane_day_turkey_nurture,
		pt: pt.plane_day_turkey_nurture
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const misty_any_boar_assure = (params = {}, options = {}) => {
	return {
		en: en.misty_any_boar_assure,
		es: es.misty_any_boar_assure,
		pt: pt.misty_any_boar_assure
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_orange_parakeet_propel = (params = {}, options = {}) => {
	return {
		en: en.aloof_orange_parakeet_propel,
		es: es.aloof_orange_parakeet_propel,
		pt: pt.aloof_orange_parakeet_propel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_less_canary_find = (params = {}, options = {}) => {
	return {
		en: en.muddy_less_canary_find,
		es: es.muddy_less_canary_find,
		pt: pt.muddy_less_canary_find
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_knotty_fly_peel = (params = {}, options = {}) => {
	return {
		en: en.just_knotty_fly_peel,
		es: es.just_knotty_fly_peel,
		pt: pt.just_knotty_fly_peel
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pretty_sad_ibex_drop = (params = {}, options = {}) => {
	return {
		en: en.pretty_sad_ibex_drop,
		es: es.pretty_sad_ibex_drop,
		pt: pt.pretty_sad_ibex_drop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mad_vivid_wasp_bask = (params = {}, options = {}) => {
	return {
		en: en.mad_vivid_wasp_bask,
		es: es.mad_vivid_wasp_bask,
		pt: pt.mad_vivid_wasp_bask
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_yummy_swan_drop = (params = {}, options = {}) => {
	return {
		en: en.nimble_yummy_swan_drop,
		es: es.nimble_yummy_swan_drop,
		pt: pt.nimble_yummy_swan_drop
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const caring_game_marten_glow = (params = {}, options = {}) => {
	return {
		en: en.caring_game_marten_glow,
		es: es.caring_game_marten_glow,
		pt: pt.caring_game_marten_glow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zany_last_ostrich_grin = (params = {}, options = {}) => {
	return {
		en: en.zany_last_ostrich_grin,
		es: es.zany_last_ostrich_grin,
		pt: pt.zany_last_ostrich_grin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const elegant_dull_rat_heal = (params = {}, options = {}) => {
	return {
		en: en.elegant_dull_rat_heal,
		es: es.elegant_dull_rat_heal,
		pt: pt.elegant_dull_rat_heal
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_weary_wren_learn = (params = {}, options = {}) => {
	return {
		en: en.due_weary_wren_learn,
		es: es.due_weary_wren_learn,
		pt: pt.due_weary_wren_learn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_arable_shad_cut = (params = {}, options = {}) => {
	return {
		en: en.last_arable_shad_cut,
		es: es.last_arable_shad_cut,
		pt: pt.last_arable_shad_cut
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const neat_actual_donkey_build = (params = {}, options = {}) => {
	return {
		en: en.neat_actual_donkey_build,
		es: es.neat_actual_donkey_build,
		pt: pt.neat_actual_donkey_build
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_wild_hawk_grow = (params = {}, options = {}) => {
	return {
		en: en.sleek_wild_hawk_grow,
		es: es.sleek_wild_hawk_grow,
		pt: pt.sleek_wild_hawk_grow
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const any_bard_rufian = (params = {}, options = {}) => {
	return {
		en: en.any_bard_rufian,
		es: es.any_bard_rufian,
		pt: pt.any_bard_rufian
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_slick_ratin = (params = {}, options = {}) => {
	return {
		en: en.yummy_slick_ratin,
		es: es.yummy_slick_ratin,
		pt: pt.yummy_slick_ratin
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ayal_barbose_ratings = (params = {}, options = {}) => {
	return {
		en: en.ayal_barbose_ratings,
		es: es.ayal_barbose_ratings,
		pt: pt.ayal_barbose_ratings
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_elegant_oryx_snap = (params = {}, options = {}) => {
	return {
		en: en.due_elegant_oryx_snap,
		es: es.due_elegant_oryx_snap,
		pt: pt.due_elegant_oryx_snap
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_watery_dove_lock = (params = {}, options = {}) => {
	return {
		en: en.alive_watery_dove_lock,
		es: es.alive_watery_dove_lock,
		pt: pt.alive_watery_dove_lock
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const agent_super_falcon_swim = (params = {}, options = {}) => {
	return {
		en: en.agent_super_falcon_swim,
		es: es.agent_super_falcon_swim,
		pt: pt.agent_super_falcon_swim
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_basic_turkey_splash = (params = {}, options = {}) => {
	return {
		en: en.dirty_basic_turkey_splash,
		es: es.dirty_basic_turkey_splash,
		pt: pt.dirty_basic_turkey_splash
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_major_crossbill_dine = (params = {}, options = {}) => {
	return {
		en: en.lost_major_crossbill_dine,
		es: es.lost_major_crossbill_dine,
		pt: pt.lost_major_crossbill_dine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_crazy_buzzard_offer = (params = {}, options = {}) => {
	return {
		en: en.cozy_crazy_buzzard_offer,
		es: es.cozy_crazy_buzzard_offer,
		pt: pt.cozy_crazy_buzzard_offer
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_spicy_pony_cut = (params = {}, options = {}) => {
	return {
		en: en.dark_spicy_pony_cut,
		es: es.dark_spicy_pony_cut,
		pt: pt.dark_spicy_pony_cut
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ email: NonNullable<unknown> }} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_calm_kangaroo_persist = (params , options = {}) => {
	return {
		en: en.dark_calm_kangaroo_persist,
		es: es.dark_calm_kangaroo_persist,
		pt: pt.dark_calm_kangaroo_persist
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ name: NonNullable<unknown> }} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_strong_snail_trim = (params , options = {}) => {
	return {
		en: en.teal_strong_snail_trim,
		es: es.teal_strong_snail_trim,
		pt: pt.teal_strong_snail_trim
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tough_yummy_bumblebee_transform = (params = {}, options = {}) => {
	return {
		en: en.tough_yummy_bumblebee_transform,
		es: es.tough_yummy_bumblebee_transform,
		pt: pt.tough_yummy_bumblebee_transform
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_grand_stork_ascend = (params = {}, options = {}) => {
	return {
		en: en.sharp_grand_stork_ascend,
		es: es.sharp_grand_stork_ascend,
		pt: pt.sharp_grand_stork_ascend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const knotty_dirty_dove_love = (params = {}, options = {}) => {
	return {
		en: en.knotty_dirty_dove_love,
		es: es.knotty_dirty_dove_love,
		pt: pt.knotty_dirty_dove_love
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const each_keen_fly_fetch = (params = {}, options = {}) => {
	return {
		en: en.each_keen_fly_fetch,
		es: es.each_keen_fly_fetch,
		pt: pt.each_keen_fly_fetch
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_lazy_lionfish_bask = (params = {}, options = {}) => {
	return {
		en: en.cozy_lazy_lionfish_bask,
		es: es.cozy_lazy_lionfish_bask,
		pt: pt.cozy_lazy_lionfish_bask
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const novel_free_dragonfly_sail = (params = {}, options = {}) => {
	return {
		en: en.novel_free_dragonfly_sail,
		es: es.novel_free_dragonfly_sail,
		pt: pt.novel_free_dragonfly_sail
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_ideal_larva_grasp = (params = {}, options = {}) => {
	return {
		en: en.major_ideal_larva_grasp,
		es: es.major_ideal_larva_grasp,
		pt: pt.major_ideal_larva_grasp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_weary_liger_hike = (params = {}, options = {}) => {
	return {
		en: en.frail_weary_liger_hike,
		es: es.frail_weary_liger_hike,
		pt: pt.frail_weary_liger_hike
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const noisy_sleek_ox_hike = (params = {}, options = {}) => {
	return {
		en: en.noisy_sleek_ox_hike,
		es: es.noisy_sleek_ox_hike,
		pt: pt.noisy_sleek_ox_hike
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_steep_midge_clip = (params = {}, options = {}) => {
	return {
		en: en.trite_steep_midge_clip,
		es: es.trite_steep_midge_clip,
		pt: pt.trite_steep_midge_clip
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_royal_tiger_strive = (params = {}, options = {}) => {
	return {
		en: en.house_royal_tiger_strive,
		es: es.house_royal_tiger_strive,
		pt: pt.house_royal_tiger_strive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_super_insect_twist = (params = {}, options = {}) => {
	return {
		en: en.sleek_super_insect_twist,
		es: es.sleek_super_insect_twist,
		pt: pt.sleek_super_insect_twist
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const just_key_barbel_succeed = (params = {}, options = {}) => {
	return {
		en: en.just_key_barbel_succeed,
		es: es.just_key_barbel_succeed,
		pt: pt.just_key_barbel_succeed
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spare_short_worm_swim = (params = {}, options = {}) => {
	return {
		en: en.spare_short_worm_swim,
		es: es.spare_short_worm_swim,
		pt: pt.spare_short_worm_swim
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grand_fluffy_goldfish_devour = (params = {}, options = {}) => {
	return {
		en: en.grand_fluffy_goldfish_devour,
		es: es.grand_fluffy_goldfish_devour,
		pt: pt.grand_fluffy_goldfish_devour
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_title = (params = {}, options = {}) => {
	return {
		en: en.terms_title,
		es: es.terms_title,
		pt: pt.terms_title
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_header = (params = {}, options = {}) => {
	return {
		en: en.terms_header,
		es: es.terms_header,
		pt: pt.terms_header
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p0 = (params = {}, options = {}) => {
	return {
		en: en.terms_p0,
		es: es.terms_p0,
		pt: pt.terms_p0
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h1 = (params = {}, options = {}) => {
	return {
		en: en.terms_h1,
		es: es.terms_h1,
		pt: pt.terms_h1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p1 = (params = {}, options = {}) => {
	return {
		en: en.terms_p1,
		es: es.terms_p1,
		pt: pt.terms_p1
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h2 = (params = {}, options = {}) => {
	return {
		en: en.terms_h2,
		es: es.terms_h2,
		pt: pt.terms_h2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p2 = (params = {}, options = {}) => {
	return {
		en: en.terms_p2,
		es: es.terms_p2,
		pt: pt.terms_p2
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h3 = (params = {}, options = {}) => {
	return {
		en: en.terms_h3,
		es: es.terms_h3,
		pt: pt.terms_h3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p3 = (params = {}, options = {}) => {
	return {
		en: en.terms_p3,
		es: es.terms_p3,
		pt: pt.terms_p3
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_h4 = (params = {}, options = {}) => {
	return {
		en: en.terms_h4,
		es: es.terms_h4,
		pt: pt.terms_h4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p4 = (params = {}, options = {}) => {
	return {
		en: en.terms_p4,
		es: es.terms_p4,
		pt: pt.terms_p4
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p5 = (params = {}, options = {}) => {
	return {
		en: en.terms_p5,
		es: es.terms_p5,
		pt: pt.terms_p5
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p6 = (params = {}, options = {}) => {
	return {
		en: en.terms_p6,
		es: es.terms_p6,
		pt: pt.terms_p6
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_p7 = (params = {}, options = {}) => {
	return {
		en: en.terms_p7,
		es: es.terms_p7,
		pt: pt.terms_p7
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_descriptiopn = (params = {}, options = {}) => {
	return {
		en: en.terms_descriptiopn,
		es: es.terms_descriptiopn,
		pt: pt.terms_descriptiopn
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_formal_gopher_aid = (params = {}, options = {}) => {
	return {
		en: en.free_formal_gopher_aid,
		es: es.free_formal_gopher_aid,
		pt: pt.free_formal_gopher_aid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spry_aqua_jackdaw_pave = (params = {}, options = {}) => {
	return {
		en: en.spry_aqua_jackdaw_pave,
		es: es.spry_aqua_jackdaw_pave,
		pt: pt.spry_aqua_jackdaw_pave
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_arable_warbler_lift = (params = {}, options = {}) => {
	return {
		en: en.flat_arable_warbler_lift,
		es: es.flat_arable_warbler_lift,
		pt: pt.flat_arable_warbler_lift
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const funny_lucky_puffin_aid = (params = {}, options = {}) => {
	return {
		en: en.funny_lucky_puffin_aid,
		es: es.funny_lucky_puffin_aid,
		pt: pt.funny_lucky_puffin_aid
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_weird_husky_slurp = (params = {}, options = {}) => {
	return {
		en: en.teal_weird_husky_slurp,
		es: es.teal_weird_husky_slurp,
		pt: pt.teal_weird_husky_slurp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const icy_low_panther_arrive = (params = {}, options = {}) => {
	return {
		en: en.icy_low_panther_arrive,
		es: es.icy_low_panther_arrive,
		pt: pt.icy_low_panther_arrive
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mushy_busy_angelfish_play = (params = {}, options = {}) => {
	return {
		en: en.mushy_busy_angelfish_play,
		es: es.mushy_busy_angelfish_play,
		pt: pt.mushy_busy_angelfish_play
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const formal_fancy_gazelle_link = (params = {}, options = {}) => {
	return {
		en: en.formal_fancy_gazelle_link,
		es: es.formal_fancy_gazelle_link,
		pt: pt.formal_fancy_gazelle_link
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_slow_llama_edit = (params = {}, options = {}) => {
	return {
		en: en.close_slow_llama_edit,
		es: es.close_slow_llama_edit,
		pt: pt.close_slow_llama_edit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_fancy_nils_edit = (params = {}, options = {}) => {
	return {
		en: en.nice_fancy_nils_edit,
		es: es.nice_fancy_nils_edit,
		pt: pt.nice_fancy_nils_edit
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_clean_capybara_ascend = (params = {}, options = {}) => {
	return {
		en: en.empty_clean_capybara_ascend,
		es: es.empty_clean_capybara_ascend,
		pt: pt.empty_clean_capybara_ascend
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_fun_tuna_praise = (params = {}, options = {}) => {
	return {
		en: en.happy_fun_tuna_praise,
		es: es.happy_fun_tuna_praise,
		pt: pt.happy_fun_tuna_praise
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const early_fine_ape_jest = (params = {}, options = {}) => {
	return {
		en: en.early_fine_ape_jest,
		es: es.early_fine_ape_jest,
		pt: pt.early_fine_ape_jest
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const even_ok_ox_renew = (params = {}, options = {}) => {
	return {
		en: en.even_ok_ox_renew,
		es: es.even_ok_ox_renew,
		pt: pt.even_ok_ox_renew
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lmp = (params = {}, options = {}) => {
	return {
		en: en.lmp,
		es: es.lmp,
		pt: pt.lmp
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_many_ladybug_kick = (params = {}, options = {}) => {
	return {
		en: en.due_many_ladybug_kick,
		es: es.due_many_ladybug_kick,
		pt: pt.due_many_ladybug_kick
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_smug_squirrel_greet = (params = {}, options = {}) => {
	return {
		en: en.suave_smug_squirrel_greet,
		es: es.suave_smug_squirrel_greet,
		pt: pt.suave_smug_squirrel_greet
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_minor_shark_rest = (params = {}, options = {}) => {
	return {
		en: en.cozy_minor_shark_rest,
		es: es.cozy_minor_shark_rest,
		pt: pt.cozy_minor_shark_rest
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ patient: NonNullable<unknown> }} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_late_monkey_stop = (params , options = {}) => {
	return {
		en: en.empty_late_monkey_stop,
		es: es.empty_late_monkey_stop,
		pt: pt.empty_late_monkey_stop
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const legal_suave_panther_sing = (params = {}, options = {}) => {
	return {
		en: en.legal_suave_panther_sing,
		es: es.legal_suave_panther_sing,
		pt: pt.legal_suave_panther_sing
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const honest_mellow_fireant_assure = (params = {}, options = {}) => {
	return {
		en: en.honest_mellow_fireant_assure,
		es: es.honest_mellow_fireant_assure,
		pt: pt.honest_mellow_fireant_assure
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{ config: NonNullable<unknown> }} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_fluffy_vulture_pop = (params , options = {}) => {
	return {
		en: en.zippy_fluffy_vulture_pop,
		es: es.zippy_fluffy_vulture_pop,
		pt: pt.zippy_fluffy_vulture_pop
	}[options.languageTag ?? languageTag()](params)
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const agent_still_kudu_shine = (params = {}, options = {}) => {
	return {
		en: en.agent_still_kudu_shine,
		es: es.agent_still_kudu_shine,
		pt: pt.agent_still_kudu_shine
	}[options.languageTag ?? languageTag()]()
}



/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "es" | "en" | "pt" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const broad_less_bee_hack = (params = {}, options = {}) => {
	return {
		en: en.broad_less_bee_hack,
		es: es.broad_less_bee_hack,
		pt: pt.broad_less_bee_hack
	}[options.languageTag ?? languageTag()]()
}

